@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import './app/amm-share/scss/typograpy.scss';

a {
  color: #282e38;
}
:root {
  --primary-text-color-white: #eeeeee;
  --secondary-text-color: #6b7280;
  --primary-text-color-black: #020617;
  --primary-background-color: #020617;
  --focus-color: #2563eb;
  --notfocus-color: #eeeeee;
  --secondary-color: #8b89e6;
  --accent-color: #e8e7fa;
  --shadow-color: #e8e8e8;
  --primary-text-size: 14px;
  --primary-text-color: #282e38;
  --secondary-text-color: #6b7280;
  --header-padding: 10px;
  --content-padding: 10px;
  --font-family-global: "Inter Var";
  --amm-surface-600: #757575;
}